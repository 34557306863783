// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-tsx": () => import("./../../../src/pages/admin.tsx" /* webpackChunkName: "component---src-pages-admin-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-article-tsx": () => import("./../../../src/pages/article.tsx" /* webpackChunkName: "component---src-pages-article-tsx" */),
  "component---src-pages-articles-tsx": () => import("./../../../src/pages/articles.tsx" /* webpackChunkName: "component---src-pages-articles-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-privacy-policy-workshop-tsx": () => import("./../../../src/pages/privacy-policy-workshop.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-workshop-tsx" */),
  "component---src-pages-store-tsx": () => import("./../../../src/pages/store.tsx" /* webpackChunkName: "component---src-pages-store-tsx" */),
  "component---src-pages-workshop-tsx": () => import("./../../../src/pages/workshop.tsx" /* webpackChunkName: "component---src-pages-workshop-tsx" */)
}

